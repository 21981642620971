<template>
  <div>
    <header-slot>
      <template #actions>
        <div
          v-if="$route.meta.route === 'Quick Message'"
          style="display: flex; flex-direction: row; justify-content: end"
        >
          <b-button
            size="lg"
            class="mr-2"
            variant="info"
            @click="openCreateQuickModal"
          >
            Create
          </b-button>
        </div>

        <div
          v-if="$route.meta.route === 'Quick Email'"
          style="display: flex; flex-direction: row; justify-content: end"
        >
          <b-button size="lg" class="mr-2" variant="info" @click="createQuick">
            Create
          </b-button>
        </div>
      </template>
    </header-slot>

    <b-nav pills class="m-0">
      <b-nav-item
        :to="{ name: 'ced-settings-app-message-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >App Message</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ced-settings-welcome-message-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Welcome Message</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ced-settings-quick-message-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Quick SMS</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ced-settings-quick-email-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Quick Email</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ced-settings-no-answer-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >No Answer</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ced-settings-dob-message-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Birthday</b-nav-item
      >
    </b-nav>
    <b-card class="px-1 pb-1">
      <router-view :key="key" />
    </b-card>

    <create-quick-sms-modal
      v-if="createQuickModal"
      @refreshTable="key++"
      @closeModal="closeCreateQuickModal"
    />
    <b-modal
      id="modal-quick-email-save-success"
      title-class="h4 text-white"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="type == 1 ? 'Edit Quick Email' : 'New Quick Email'"
      hide-footer
    >
      <modal-quick-email-save
        :quick="addquick"
        :modul="23"
        @refreshTable="key++"
        :type="type"
      />
    </b-modal>
  </div>
</template>

<script>
import CreateQuickSmsModal from "@/views/ce-digital/sub-modules/settings/views/messages/components/quick-messages/modals/CreateQuickSmsModal.vue";
import ModalQuickEmailSave from "@/views/commons/components/clients/components/clientsEmail/ModalQuickEmailSave.vue";

export default {
  name: "CeSetMessages",
  components: {
    CreateQuickSmsModal,
    ModalQuickEmailSave,
  },
  data() {
    return {
      createQuickModal: false,
      key: 0,
      addquick: {},
      type: "",
    };
  },
  methods: {
    openCreateQuickModal() {
      this.createQuickModal = true;
    },
    closeCreateQuickModal() {
      this.createQuickModal = false;
    },
    createQuick() {
      this.type = 0;
      this.addquick = { id: "", content: "", subject: "" };
      this.$bvModal.show("modal-quick-email-save-success");
    },
  },
};
</script>

<style scoped></style>
